@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;600&display=swap');

body
{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-family: 'Kanit', sans-serif;
}

.clear
{
    clear: both;
}

.whats_icon
{
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    cursor: pointer;
}

.whats_icon img
{
    width: 50px;
    height: auto;
}

.intro
{
    width: 100%;
    height: 100%;
}

.main
{
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
}

.btn_big
{
    border: none;
    background-color: #61a730;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    margin: 10px auto;
    margin-right: 10px;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.btn_line
{
    border: 2px solid #61a730;
    background-color: transparent;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    color: #61a730;
    margin: 10px auto;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.btn
{
    border: none;
    background-color: #61a730;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    margin: 10px auto;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.btn:hover, .btn_big:hover
{
    background-color: #476e2d;
}

.btn_line:hover
{
    border: 2px solid #476e2d;
    color: #476e2d;
}

.btn_cancel
{
    border: none;
    background-color: #7c7b7b;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    margin: 10px auto;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.btn_cancel:hover
{
    background-color: #545454;
}

.btn_cancel_min
{
    border: none;
    background-color: #7c7b7b;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 1px;
    margin-right: 5px;
    font-weight: bold;
}

.btn_cancel_min:hover
{
    background-color: #545454;
}

.btn_min
{
    border: none;
    background-color: #61a730;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 1px;
    margin-right: 5px;
    font-weight: bold;
}

.btn_lat
{
    display: block;
    width: 100%;
    margin-bottom: 2px;
}

.btn_follow
{
    margin-top: 10px;
}

.btn_min:hover
{
    background-color: #476e2d;
}

.btn_min_gold
{
    border: none;
    background-color: #f9da47;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: bold;
}

.btn_min_gold2
{
    border: none;
    background-color: #f9da47;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 1px;
    margin-right: 5px;
    margin-top: 10px;
    font-weight: bold;
}

.btn_min_gold:hover, .btn_min_gold2:hover
{
    background-color: #e0c440;
}

.textlink
{
    color: #61a730;
    text-decoration: none;
    display: inline-block;
    margin: 5px 0;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
}

.textlink:hover
{
    color: #476e2d;
}

.loading_msg
{
    width: 100%;
    text-align: center;
}

/*FIRST PAGE*/
.in_page
{
    width: 100%;
    background-color: #fff;
    background-image: url('../images/back.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right;
}

.in_text
{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: left;
}

.in_text2
{
    width: 90%;
    padding: 5%;
    background-color: #f8f8f8;
    text-align: center;
    border-bottom: 1px solid #dddddd;
}

.in_icons
{
    width: 100%;
    margin-top: 50px;
}

.in_icon
{
    width: 23%;
    padding: 1%;
   float: left;
}

.in_icon img
{
    width: 100%;
    max-width: 80px;
    height: auto;
}

.in_icon p
{
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.in_text3
{
    width: 90%;
    padding: 5%;
    background-color: #f8f8f8;
    text-align: center;
}

.in_box
{
    background-color:rgba(255, 255, 255, .8);
    border-right: 10px solid #61a730;
    padding: 30px;
    width: 400px;
    text-align: left;
}

.in_box p
{
    font-size: 18px;
}

.btns_intro button
{
    margin-right: 10px;
}

.in_list
{
    width: 23%;
    padding: 1;
    text-align: left;
    float: left;
    margin-top: 30px;
}

.sub_txt p
{
    margin: 0;
    line-height: 1;
    margin-bottom: 10px;
}

.in_list li
{
    list-style-type: none;
    text-align: left;
}

.in_page h1
{
    margin: 0;
    font-size: 35px;
    line-height: 1.2;
}

.in_page h2
{
    margin: 0;
    font-size: 30px;
}

.in_sub
{
    margin: 0;
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: bold;
}

.load_more_btn
{
    width: 100%;
    text-align: center;
}

/*TOP*/
.top
{
    width: calc(100% - 40px);
    height: auto;
    padding: 10px 20px;
    padding-top: 20px;
    background-color: #f8f8f8;
}

.im_top
{
    width: 200px;
    height: auto;
    float: left;
    cursor: pointer;
}

.user_top
{
    float: right;
    margin-top: 10px;
}

.user_top p
{
    margin: 0;
    float: right;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    color: #476e2d;
}

.user_top p:hover
{
    color: #61a730
}

.top_intro
{
    float: left;
}

.menu
{
    width: 100%;
    height: auto;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
    background-color: #61a730;
}

.menu p
{
    display: inline;
    pointer-events: none;
    color: #476e2d;
    padding: 0 5px;
}

.menulink
{
    margin: 5px;
    color: #f8f8f8;
    text-decoration: none;
    font-size: 16px;
}

.menulinkactive
{
    color: #fff;
    margin: 5px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.textlink
{
    color: #61a730;
    text-decoration: none;
    font-weight: normal !important;
    margin: 0;
    letter-spacing: normal;
}

.textlink2
{
    color: #61a730;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal !important;
    margin: 0;
    letter-spacing: normal;
    display: inline;
}

.textlink3
{
    color: #61a730;
    text-decoration: none;
    font-weight: normal !important;
    margin: 0;
    letter-spacing: normal;
    display: block;
}

.textlink4
{
    color: #61a730;
    text-decoration: none;
    font-weight: normal !important;
    font-size: 14px;
    margin: 0;
    letter-spacing: normal;
    display: block;
}

.menulink:hover, .textlink:hover, .textlink2:hover, .textlink3:hover, .textlink4:hover
{
    color:#476e2d;
}

.new_msgs
{
    font-weight: bold;
}

.lang_selector
{
    position: absolute;
    top: 0;
    right: 0
}

.lang_selector button
{
    border: none;
    display: block;
    float: left;
    padding: 0px 10px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: bold;
    background-color: #f8f8f8;
}

#lang_sel_esp
{
    border-right: 0px solid #476e2d;
}

.selected_lang
{
    background-color: #dddddd !important;
}

/*FOOTER*/
.footer
{
    width: calc(100% - 40px);
    text-align: center;
    padding: 20px;
}

.footer a
{
    text-decoration: none;
    color: #61a730;
    font-weight: bold;
}

.footer p
{
    margin: 0;
    font-size: 14px;
}

.socials img
{
    width: 40px;
    height: auto;
    margin: 0 10px;
}

/*LOGIN - REGISTER*/
.log_box
{
    width: 80%;
    max-width: 500px;
    margin: 50px auto;
    margin-bottom: 0px;
    text-align: center;
    border: 1px solid #dddddd;
    padding: 20px;
}

.log_box img
{
    width: 60%;
    height: auto;
}

.field
{
    margin-bottom: 10px;
}

#field_w
{
    display: none;
}

.field label
{
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
}

.field input
{
    display: block;
    width: calc(100% - 12px);
    padding: 5px;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.field textarea
{
    display: block;
    width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    padding: 5px;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.field select
{
    display: block;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.log_box2
{
   margin: 20px auto;
   border-top: 1px solid #dddddd;
   padding-top: 20px;
}

.log_box2 p
{
    margin: 0;
    font-size: 20px;
}
.log_box button
{
    display: block;
    margin-top: 20px;
}

.check_politic
{
    width: 100%;
    text-align: left;
}

.check_politic #terms
{
    float: left;
    margin-right: 5px;
}

.check_politic label
{
    float: left;
    margin-top: -3px;
    width: calc(90% - 20px);
}

.terms_link
{
    color: #61a730;
    text-decoration: none;
}

/*USER*/
.top_buttons
{
    width: 100%;
    margin-top: 20px;
}

.top_btn_set button
{
    width: 100% !important;
}

.bottom_btn_set
{
    width: 100%;
    text-align: center;
}

.profile_box
{
    width: 100%;
    position: relative;
}

.subtop_buttons
{
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.subtop_buttons button
{
    margin-bottom: 5px;
    display: inline-block;
}

.profile_top
{
    width: calc(28% - 20px);
    padding: 2%;
    float: left;
    margin:0;
    margin-right: 20px;
    margin-top: 20px;
    text-align: center;
    position: sticky;
    top: 20px;
    left: 0;
    background-color: #1A411E;
    border-radius: 20px;
}

.pos_top_profile
{
    margin-bottom: 5px !important;
}

.profile_wall
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-decoration: none;
    color: #61a730;
}

.profile_wall:hover
{
    color: #476e2d;
}

.profile_timeline
{
    width: 68%;
    float: right;
}

.profile_buttons button
{
    margin-right: 10px;
}

.profile_photo
{
    width: 108px;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
}

.profile_photo_wall
{
    width: 54px;
    height: auto;
    margin-right: 10px;
}

.profile_photo_im
{
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 4px solid #dddddd;
    border-radius: 100px;
}

.profile_photo_im_wall
{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 2px solid #dddddd;
    border-radius: 50px;
}

.profile_pic
{
    border-radius: 100px;
    object-fit: cover;
    width: 100%;
    height: 100px;
}

.profile_pic_wall
{
    border-radius: 50px;
    object-fit: cover;
    width: 100%;
    height: 50px;
}

.pic_btn
{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

#add_image_box
{
    width: calc(100% - 40px);
    border: 1px solid #dddddd;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    display:none;
}

.profile_data p, .profile_data h1
{
    margin: 0;
    line-height: 1.1;
    margin-top: 10px;
    color: #fff;
}

.profile_data h1
{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* Evitar cortar palabras más cortas */
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
}

.profile_wall .profile_data p, .profile_wall .profile_data h1
{
    margin: 0;
    font-size: 16px;
    color:#61a730;
}


.profile_nums
{
    margin: 20px 0;
    width: calc(100% - 10px) !important;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px;
}

.profile_num
{
    margin-bottom: 0px;
    width: 100%;
    display: block;
}

.profile_num_t
{
    font-weight: bold;
    display: inline-block;
    margin-right: 5px !important;
}

.profile_num_d
{
    display: inline-block;
}

.profile_num p
{
    margin: 0;
    color: #476e2d !important;
}

.profile_bio
{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20px;
    margin: 20px auto;
}

.profile_bio p
{
    font-size: 18px;
    margin: 0;
}

.profile_bio2
{
    margin-top: 20px;
}

.profile_available
{
   
    margin-top: 20px;
    text-align: center;
    border: 1px solid #61a730;
    padding: 5px;
    border-radius: 10px;
}


.profile_available_stat p
{
    margin: 0;
    font-size: 16px;
    margin-bottom: 3px;
}

.profile_available_notset
{
    margin: 0 !important;
}

.profile_available_date
{
    font-size: 12px;
    margin: 0 !important;
}


.profile_stats
{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20px;
    margin: 20px auto;
}

.profile_stats h3
{
    margin: 0;
}

.profile_tt
{
    margin-bottom: 20px;
}

.isyour
{
    border: 1px solid #7c7b7b;
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 12px;
    color: #7c7b7b;
    letter-spacing: 1px;
    font-weight: bold;
    display: block;
    width: calc(100% - 22px);
    margin: 0;
    margin-bottom: 2px;
    text-align: center;
}


.stat
{
    margin: 5px 0px;
}

.stat p
{
    margin: 0;
    display: inline;
    margin-right: 5px;
    font-size: 18px;
}

.stat_t
{
    font-weight: bold;
}

.stat_e
{
    display: block !important;
    font-weight: bold;
    margin-bottom: 20px !important;
}

.profile_boxes
{
    width: 100%;
    border-radius: 20px;
    margin: 20px auto;
}

.profile_offers
{
    width: 100%;
    border-radius: 20px;
    margin: 20px auto;
}

.profile_boxes h2, .profile_offers h2
{
    margin: 0;
}

.offer_box
{
    text-decoration: none;
    color: #000;
    background-color: #f8f8f8;
    display: block;
    padding-left: 5px;
    margin: 5px 0;
    padding: 20px;
    border-radius: 20px;
}

.offer_box p
{
    margin: 0;
    font-size: 18px;
}

#offers_box
{
    display: none;
}

.note_txt
{
    font-size: 16px !important;
    margin-top: 10px !important;
    font-weight: bold;
}

.video_element
{
    width: calc(33.33% - 10px);
    height: auto;
    float: left;
    margin: 5px;
}

.videos_list
{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20px;
}

.videos_list button
{
    margin-top: 5px;
}

.videos_list button:hover
{
    background-color: #000;
    color: #fff;
}

.video_element iframe
{
    width: 100%;
    height: auto;
    display: block;
}

#add_video_box
{
    width: calc(100% - 40px);
    border: 1px solid #dddddd;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    display: none;
}

#add_video_box input
{
    display: block;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
}

#add_video_box button
{
    margin-right: 10px;
}

#add_team_box
{
    width: calc(100% - 40px);
    border: 1px solid #dddddd;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    display: none;
}

.team_field
{
    width: 100%;
}

#team_field_w
{
    display: none;
}

.team_field input
{
    width: 97%;
    box-sizing:content-box;
    padding: 1%;
}

.team_field select
{
    width: 97.5%;
    padding: 1%;
    box-sizing:content-box;
}


.team_field label
{
    display: block;
}

.team_subfield
{
    display: block;
}

#add_team_box button
{
    margin-right: 10px;
}

.teams_list
{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 20px;
}

.team_element
{
    margin: 10px 0;
    padding-left: 10px;
    border-left: 2px solid #dddddd;
}

.team_element p
{
    margin: 0;
}

.verificated_line
{
    display: inline-block;
    margin-right: 3px;
}

.verificated_line .btn_min
{
    margin-right: 0px !important;
}

.noverificated
{
    display: block;
    margin: 0 !important;
    padding: 2px 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #7c7b7b;
    color: #7c7b7b;
}

.verificated
{
    display: block;
    margin: 0 !important;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;
    background-color: rgb(45, 163, 241);
    border: 1px solid rgb(88, 213, 252);
    color: #fff !important;
}

.verificated2
{
    display: block;
    margin: 0 !important;
    padding: 2px 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ff7606;
    color: #ff7606;
}

.msgs_buttons
{
    display: inline;
}

.error_sub
{
    font-size: 14px;
    line-height: 15px;
    color: #fff !important;
}
/*POPUP*/
.popup
{
    position: fixed;
    left: 0;
    top:0;
    background-color: rgba(221, 221, 221, .9);
    width:100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    z-index: 1000;
}

#popup_blocker
{
    display: flex;
}


.popup .btn
{
    margin: 10px;
}

.popup_box
{
    width: calc(60% - 60px);
    height: auto;
    text-align: center;
    padding: 30px;
}

.popup_box2
{
    width: calc(60% - 60px);
    text-align: center;
    background-color: #fff;
    padding: 30px;
    max-width: 500px;
}

.popup_t
{
    font-size: 18px;
    color: #fff;
    margin: 0;
    background-color: #000;
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
}

.popup_t span
{
    font-weight: bold;
    font-size: 20px;
}

.popup_t2
{
    font-size: 20px;
    color: #000;
    margin: 0;
    font-weight: bold;
    margin-bottom: 30px;
}

.pop_buttons
{
    margin-top: 30px;
}

.popup_box textarea
{
    width: calc(100% - 42px);
    max-width: 100%;
    padding: 20px;
    height: 100px;
    border-radius: 0 0px 20px 20px;
    outline: none;
    border: 1px solid #dddddd;
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
}

.pop_input
{
    width: 100%;
    margin-top: 10px;
}

.pop_input label
{
    display: block;
}

.offer_txt
{
    width: calc(90% - 12px);
    padding: 10px;
    border: 1px solid #dddddd;
}

.gral_input
{
    display: block;
    width: 90%;
    margin-left: 5%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.sec_input
{
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.preview_img
{
    width: 100%;
    margin-top: 20px;
    height: auto;
}

.preview_img img
{
    display: block;
    max-width: 100%;
}

/*LIST PLAYERS - AGENTS - TRAINERS*/
.list_box
{
    width: 80%;
    height: auto;
    margin: 30px auto;
}

.list_box h1
{
    margin: 0;
    margin-bottom: 20px;
    font-size: 22px;
    width: 100%;
    text-align: center;
}

.list_box2
{
    width: 100%;
    padding-bottom: 30px;
}

.list_box2 h3
{
    text-align: center;
}

.list_item
{
    width: calc(100% - 20px);
    height: auto;
    background-color: #f8f8f8;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
    display: block;
    color: #000;
}

.list_item:hover
{
    background-color: #61a730;
    color:#fff;
}

.list_item2
{
    width: calc(100% - 20px);
    height: auto;
    background-color: #f8f8f8;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 5px;
    display: block;
    color: #000;
}

.list_item3
{
    width: calc(100% - 20px);
    height: auto;
    margin-bottom: 5px;
    display: block;
    color: #61a730;
}

.list_item3:hover
{
    color: #476e2d;
}

.list_item5
{
    width: 50%;
    height: auto;
    margin-bottom: 5px;
    display: block;
    color: #61a730;
    float: left;
}

.list_item4
{
    width: 20%;
    height: auto;
    margin-bottom: 5px;
    display: block;
    color: #61a730;
    float: left;
}

.list_item5:hover, .list_item4:hover
{
    color: #476e2d;
}

.list_item2 button
{
    margin-top: 5px;
}

.list_link
{
    color: #61a730;
    text-decoration: none;
    display: block;
}

.list_link:hover
{
    color: #476e2d;
}

.list_photo
{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #dddddd;
    float: left;
    border-radius: 50px;
}

.list_photo2
{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 50px;
    display: block;
    margin: 0 auto;
}

.list_pic
{
    border-radius: 100px;
    object-fit: cover;
    width: 100%;
    height: 50px;
}

.list_data
{
    float: left;
    margin-left: 10px;
    width: calc(100% - 70px);
}

.list_data2
{
    margin-top: 5px;
    margin-bottom: 10px;
}

.list_data3
{
    float: left;
    margin-left: 10px;
    width: calc(100% - 70px);
}

.list_data p
{
    margin: 0;
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.list_data3 p
{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.wall_item
{
    margin-top: 10px;
}

.list_name
{
    font-size: 18px;
    font-weight: bold;
}

.list_name2
{
    font-size: 18px;
    font-weight: bold;
    color: #61a730;
    text-decoration: none;
}

.list_name2:hover
{
    color: #476e2d;
}

.list_new
{
    font-size: 18px;
    font-weight: bold;
}

.list_pos
{
    font-size: 14px !important;
}

.list_more
{
    display: none;
    margin-top: 10px;
}

.search_box
{
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.search_area
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_area button
{
    position: absolute;
    right: 0;
    border-radius: 0 20px 20px 0px;
    height: 100%;
    padding: 0 10px;
    margin-right: 0;
    display: block;
}

#reset_btn
{
    display: none;
}

.advanced_box
{
    width: 98%;
    border-radius: 10px;
    margin-top: 2%;
    padding: 1%;
    background-color: #f8f8f8;
    text-align: center;
}

.advanced_box p
{
    text-align: center;
    margin: 0;
    margin-bottom: 2%;
    font-weight: bold;
}

.advanced_box button
{
    margin-bottom: 2%;
}

#search_field
{
    width: calc(100% - 20px);
    border-radius: 20px;
    padding: 10px;
    border: 2px solid #61a730;
    outline: none;
}

.search_inp
{
    width: 18%;
    float: left;
    margin: 1%;
}

.search_inp2
{
    width: 23%;
    float: left;
    margin: 1%;
}

.search_inp label, .search_inp2 label
{
    margin: 0;
    font-size: 14px;
    float: left;
}

.search_inp2 p
{
    margin: 0;
    font-size: 14px;
    padding: 5px;
    float: right;
    background-color: #7c7b7b;
    color: #fff;
}

.search_inp2 input
{
    width: 100% !important;
}

.search_inp select
{
    width: 100% !important;
}

input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    padding: 5px 0;
    margin: 0;
    background-color: transparent;
  }
  
  /* Track */
  input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  
  /* Thumb */
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    background: #61a730;
    margin-top: -6px;
  }
  
  /* Firefox */
  input[type=range]::-moz-range-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  
  input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    background: #61a730;
  }
  
  /* Edge and IE */
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  input[type=range]::-ms-fill-lower {
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  
  input[type=range]::-ms-fill-upper {
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  
  input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    background: #61a730;
  }
  
  /* Safari */
  input[type=range]::-webkit-slider-thumb:active {
    background: #61a730;
  }
  



/*CHAT*/
.chat_auth
{
    max-width: 50%;
    float: right;
    display: block;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 5px;
    border: 1px solid #dddddd;
}

.chat_noauth
{
    background-color: #dddddd;
    max-width: 50%;
    float: left;
    display: block;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.chat_window
{
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
}

.chat_title
{
    width: calc(100%-22px);
    padding: 10px;
    background-color: #f8f8f8;
    border: 1px solid #dddddd;
    border-bottom: none;
}

.chat_title h1
{
    margin: 0;
    font-size: 18px;
}

.chat_title h2
{
    margin: 0;
    font-size: 18px;
    font-weight: normal;
}

.chat_info
{
    width: 100%;
    margin-top: 10px;
}

.chat_info p
{
    margin: 0;
    float: left;
    margin-right: 20px;
}

.chat_auth p, .chat_noauth p
{
    margin: 0;
}

.chat_area
{
    width: calc(100% - 42px);
    padding: 20px;
    border: 1px solid #dddddd;   
}

.chat_msg
{
    width: 100%;
    background-color: #dddddd;
    height: 50px;
}

.chat_txt
{
    width: calc(80% - 12px);
    border: none;
    padding: 5px;
    float: left;
    height: calc(50px - 11px);
    border-left: 1px solid #dddddd;
    outline: none;
}

.chat_btn
{
    float: left;
    width: 20%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    background-color: #61a730;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.chat_m
{
    font-size: 16px;
}

.chat_u
{
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #61a730;
}


/*****************RESPONSIVE*************************/
@media only screen and (max-width: 800px) {
   
    .in_list
    {
        width: 48%;

    }

    .in_icon img
    {
        width: 100%;
        max-width: 80px;
        height: auto;
    }

    .in_icon p
    {
        font-size: 16px;
    }
    
    .video_element
    {
        width: calc(50% - 10px);
    }  
    
    .profile_top
    {
        width: 92%;
        padding: 4%;
        float: none;
        margin: 0;
        margin-right: 0px;
        margin-top: 20px;
        text-align: center;
        position: static;
    }

    .profile_timeline
    {
        width: 100%;
        float: none;
    }

    .top
    {
        text-align: center;
    }

    .im_top
    {
        width: 200px;
        height: auto;
        float: none;
        cursor: pointer;
    }

    .user_top
    {
        float: none;
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }

    .user_top p
    {
        float: none;
        display: inline;
    }

    .list_box
    {
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
   
    .video_element
    {
        width: calc(100% - 10px);
    }  

    .in_icon
    {
        width: 48%;
        padding: 1%;
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 500px) {
   
    
    .in_list
    {
        width: 98%;

    }

}